import { alpha } from "@mui/material";
import { paletteObject } from "../foundations/palette";

export const MuiOutlinedInput = {
	styleOverrides: {
		root: {
			minHeight: "40px",
			fontSize: 14,
			"& .MuiOutlinedInput-notchedOutline": {
				borderWidth: 2,
				borderColor: paletteObject.grey20.main,
			},
			"&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
				borderColor: paletteObject.danger.main,
			},
			"&.Mui-disabled": {
				"& .MuiOutlinedInput-notchedOutline": {
					borderColor: paletteObject.grey20.main,
					backgroundColor: alpha(paletteObject.light.main, 0.4),
				},
			},
		},
	},
};
